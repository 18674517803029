@font-face {
    font-family: Proxima-Regular;
    src: url(../fonts/proxima/ProximaNova-Regular.otf);
    font-display: swap;
}

@font-face {
    font-family: Proxima-Thin;
    src: url(../fonts/proxima/Proxima-Nova-Thin.otf);
    font-display: swap;
}

@font-face {
    font-family: Proxima-Bold;
    src: url(../fonts/proxima/Proxima-Nova-Bold.otf);
    font-display: swap;
}

@font-face {
    font-family: Proxima-Extrabold;
    src: url(../fonts/proxima/Proxima-Nova-Extrabold.otf);
    font-display: swap;
}

.font-normal {
    font-family: Proxima-Regular;
}

.font-thin {
    font-family: Proxima-Thin;
}

.font-bold {
    font-family: Proxima-Extrabold;
}

.font-semibold {
    font-family: Proxima-Bold;
}

/* Landing New || Michroma font */

@font-face {
    font-family: Michroma-Regular;
    src: url(../fonts/michroma/Michroma-Regular.ttf);
    font-display: swap;
}

.mi--font-regular {
    font-family: Michroma-Regular;
}

/* Landing New || Syne font */
@font-face {
    font-family: Syne-Regular;
    src: url(../fonts/syne/Syne-Regular.ttf);
    font-display: swap;
}


@font-face {
    font-family: Syne-Bold;
    src: url(../fonts/syne/Syne-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: Syne-SemiBold;
    src: url(../fonts/syne/Syne-SemiBold.ttf);
    font-display: swap;
}
@font-face {
    font-family: Syne-Medium;
    src: url(../fonts/syne/Syne-Medium.ttf);
    font-display: swap;
}

.sy--font-regular {
    font-family: Syne-Regular;
}

.sy--font-medium {
    font-family: Syne-Medium;
}

.sy--font-bold {
    font-family: Syne-Bold;
}

.sy--font-semibold {
    font-family: Syne-SemiBold;
}

/* Landing New || Epilogue font */
@font-face {
    font-family: Epilogue-Light;
    src: url(../fonts/epilogue/Epilogue-Light.ttf);
    font-display: swap;
}


@font-face {
    font-family: Epilogue-Bold;
    src: url(../fonts/epilogue/Epilogue-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: Epilogue-SemiBold;
    src: url(../fonts/epilogue/Epilogue-SemiBold.ttf);
    font-display: swap;
}
@font-face {
    font-family: Epilogue-Medium;
    src: url(../fonts/epilogue/Epilogue-Medium.ttf);
    font-display: swap;
}

.ep--font-light {
    font-family: Epilogue-Light;
}

.ep--font-medium {
    font-family: Epilogue-Medium;
}

.ep--font-bold {
    font-family: Epilogue-Bold;
}

.ep--font-semibold {
    font-family: Epilogue-SemiBold;
}

/* Landing New || EXO font */
@font-face {
    font-family: Exo2-Thin;
    src: url(../fonts/exo/Exo2-Thin.ttf);
    font-display: swap;
}


@font-face {
    font-family: Exo2-Regular;
    src: url(../fonts/exo/Exo2-Regular.ttf);
    font-display: swap;
}

.ex--font-thin {
    font-family: Exo2-Thin;
}

.ex--font-Regular {
    font-family: Exo2-Regular;
}
