/* You can add global styles to this file, and also import other style files */

@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import url("../src/assets/css/fonts.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

.pointer {
  cursor: pointer !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar{  display: none}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar{  -ms-overflow-style: none;
    scrollbar-width: none;
}
/* .bg__dots{  background:#121212 url(/../assets/images/bg-banner.png) no-repeat center;  background-size:cover;  position:sticky; background-attachment: fixed;

} */

.text__yg--gradient {
  background: -webkit-linear-gradient(89.98deg, #F5D255 -3.37%, #36E7C4 99.98%);
  background: linear-gradient(89.98deg, #F5D255 -3.37%, #36E7C4 99.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.01em;

}
.text__grey--gradient {
  background: linear-gradient(180deg, rgb(255 255 255) 0%, rgb(255 255 255 / 0%) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  -webkit-text-fill-color: black;
  color: #232d2d;
  opacity: 0.5;
  font-weight: 400;
  /* animation: text-stroke 15s ease-in-out infinite; */
  background-size: 200%;
  letter-spacing: -0.005em;
}
.shadow-primary {
  box-shadow: 0px 24px 32px rgba(78, 31, 163, 0.06), 0px 16px 24px rgba(78, 31, 163, 0.06), 0px 4px 8px rgba(78, 31, 163, 0.06), 0px 0px 1px rgba(78, 31, 163, 0.06);
}
.hover\:shadow-primary:hover {
  box-shadow: 0px 24px 32px rgba(78, 31, 163, 0.06), 0px 16px 24px rgba(78, 31, 163, 0.06), 0px 4px 8px rgba(78, 31, 163, 0.06), 0px 0px 1px rgba(78, 31, 163, 0.06)!important;
}
.footer__menu--item {
  display: inline-block;
  position: relative;
}

.footer__menu--item::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -.25rem;
  left: 0;
  background-color: #FFF;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}


.footer__menu--item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.animate-slide:hover img {
  animation: slide 1s ease-in-out infinite;
}
.animate-slide:hover > .animate-slide__img-div .arrow--slide{
  animation: slide 1s ease-in-out infinite;
}
.arrow-up:hover img {
  transform-origin: 0% 50%;
  animation: slide-up 1s ease-in-out infinite;
}
.animate-blink {
  animation: blink 2s ease-in-out infinite;
}
.animate-float {
  animation: floating 2s ease-in-out infinite;
}

.token__features:nth-child(odd) .animate--float,
.upcoming:nth-child(odd) > .animate--float,
.bx__infinity-chain>div:nth-child(odd) > .animate--float,
.next__features>div > .animate--float,
.nft__features > img {
  animation: floating 2s ease-in-out infinite !important;
}
.token__features:nth-child(even) .animate--float,
.upcoming:nth-child(even) > .animate--float,
.next__features--second>div > .animate--float,
.bx__infinity-chain>div:nth-child(even) > .animate--float {
  animation: floating-even 2s ease-in-out infinite !important;
}

@keyframes slide-up {
  0%,
  100% {
    transform: translate(0, 0) rotate(5deg);
  }

  50% {
    transform: translate(10px, 10px) rotate(5deg);
  }
}


@keyframes slide {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(.75, .75);
  }
  25% {
    opacity: .25;
  }
  50% {
    opacity: .75;
    transform: scale(1, 1);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(.75, .75);
  }
}

@keyframes text-stroke {
  0% {
      background-position: 0 0;
  }

  50% {
      background-position: 400% 0;
  }

  100% {
      background-position: 0 0;
  }
}
@keyframes floating {
  from { transform: translate(0,  0px); }
  50%  { transform: translate(0, 10px); }
  to   { transform: translate(0, -0px); }
}
@keyframes floating-even {
  0% { transform: translate(0,  10px); }
  50%  { transform: translate(0, -0px); }
  100%   { transform: translate(0, 10px); }
}

.text-shadow:hover{  /* color:#5733a5; */
    text-shadow:1px 1px 8px #5733a5}
/* button{  transition: border-color 0.5s, background-color 0.5s, color 0.5s} */

@layer base{  /* CARD STYLES */
  /* .bg__dots{
    @apply bg-no-repeat bg-[url(/../assets/images/bg-banner.png)] sticky;
  } */
  .bg__dots{
    @apply bg-[url(/../assets/landing-new/images/bg-pattern.png)] bg-repeat;
  }
    .card--main{      @apply h-full max-w-8xl mx-auto flex flex-col justify-start items-center relative;  }
    .card__main--start{      @apply h-full max-w-8xl mx-auto flex flex-col justify-start items-start relative;  }
    .card__main--web{      @apply h-full mx-auto flex flex-col justify-start items-start relative;  }
    .card--width{      @apply w-full px-8 lg:w-full;  }
    .card--pad{      @apply px-8 lg:px-0 pb-12 lg:pb-0;  }
    .card{      @apply bg-[#191a1e] shadow-lg p-4 md:p-8 rounded-[20px];  }
    .flex-between{      @apply flex justify-between items-center;  }
    .banner__card{      @apply bg-secondary-light p-5 2xl:p-8 rounded-xl;  }
    .banner__card--title{      @apply text-secondary text-sm 2xl:text-base font-normal;  }
    .banner__card--subtitle{      @apply text-xl xl:text-4xl font-bold text-white leading-normal 2xl:leading-relaxed;  }
    .card__title{      @apply text__yg--gradient font-semibold text-sm md:text-base lg:text-lg mi--font-regular lg:!leading-[1.35] pb-2  }
    /* .card__title{      @apply font-bold text-white text-base md:text-xl 2xl:leading-10;  } */
    .card__description{      @apply text-[#D9D9D9] text-sm lg:text-base ep--font-light lg:!leading-[1.75] lg:tracking-wide  }
    /* .card__description{      @apply font-semibold text-sm md:text-base 2xl:text-lg 2xl:leading-5 text-secondary;  } */
    .border--b{      @apply border-grey border-b border-solid my-3;  }
    /* INPUT STYLES */
    .input--label{      @apply block text-xs sm:text-sm md:text-base 2xl:text-lg font-semibold text-[#d9d9d9];  }
    .input--label__md{      @apply block text-xs sm:text-xs md:text-sm 2xl:text-base font-semibold text-[#d9d9d9];  }
    .input--field{      @apply p-4 block w-full font-semibold text-white text-sm md:text-base 2xl:text-lg bg-[#d9d9d908] border-[#d9d9d908] rounded-lg md:rounded-xl focus:outline-none focus:border-primary border-2 border-solid;  }
    /* .input--field{      @apply p-4 block w-full font-semibold text-white text-sm md:text-base 2xl:text-lg bg-[#d9d9d908] border-[#1F2024] rounded-lg md:rounded-xl focus:outline-none focus:border-primary border-2 border-solid;  } */
    .input--field__sm{      @apply py-2 px-4 block w-full font-semibold text-white text-xs md:text-sm 2xl:text-base bg-[#d9d9d908] border-[#d9d9d908] rounded-lg md:rounded-xl focus:outline-none focus:border-primary border border-solid;  }
    .input--field__md{      @apply py-3 px-4 block w-full font-semibold text-white text-xs md:text-sm 2xl:text-base bg-[#d9d9d908] border-[#d9d9d908] rounded-lg focus:outline-none focus:border-primary border-[2px] border-solid;  }

    .input--error {
      @apply text-xs xl:text-sm font-normal text-danger animate-shake absolute xl:leading-9;
    }
    .input--warning {
      @apply text-xs xl:text-sm font-normal text-[#facc15] animate-shake absolute xl:leading-9;
    }

    /* BUTTON STYLES */
    .btn-primary{      @apply bg-[#4E1FA3]/30 border-2 border-solid border-[#4E1FA3] font-semibold text-sm md:text-base lg:text-lg text-white px-8 2xl:px-12 p-4 md:p-4 rounded-md lg:rounded-xl flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none btn__primary--anim;  }
    .btn__primary{      @apply font-semibold text-xs md:text-base lg:text-lg text-white py-3 px-4 md:py-2 lg:py-4 2xl:px-12 rounded-md lg:rounded-lg lg:rounded-xl flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none btn__primary--anim;  }
    .btn__primary-light{      @apply bg-transparent w-full border-primary-light border border-solid text-primary-light font-normal text-xs md:text-base lg:text-lg py-3 rounded-lg flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none btn__primary-light--anim;  }
    /* .btn-primary-light{      @apply bg-primary border-2 border-solid border-primary font-semibold text-xs md:text-base lg:text-lg text-white px-8 2xl:px-12 p-2 md:p-4 rounded-md lg:rounded-xl flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none btn__primary--anim;  } */
    .btn__primary--sm{      @apply bg__primary--sm py-3 px-4 border-2 border-solid font-normal rounded-lg text-xs md:text-base lg:text-lg text-white flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none btn__primary--anim  }
    .btn-primary-dark{      @apply bg-primary-dark border-2 border-solid border-primary-dark font-semibold text-xs md:text-base lg:text-lg text-white px-8 2xl:px-12 p-2 md:p-4 rounded-md lg:rounded-md flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none hover:scale-[1.01] hover:shadow-lg ease-out duration-300;  }
    .btn__secblack--sm {
      @apply bg-secondary-black border-secondary-black border-2 border-solid font-semibold text-xs md:text-base lg:text-lg text-white py-3 px-4 focus:outline-none active:outline-none focus-within:outline-none;
    }
    .btn-secondary{      @apply bg-secondary-dark border-2 border-solid border-secondary-dark font-semibold text-xs md:text-base lg:text-lg text-white px-8 2xl:px-12 p-2 md:p-4 rounded-md lg:rounded-xl flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none hover:scale-[1.01] hover:shadow-lg ease-out duration-300;  }
    .btn-primary-outline{      @apply bg-secondary-dark border-2 border-solid border-primary font-normal text-xs md:text-base lg:text-lg text-white px-8 2xl:px-12 p-2 md:p-4 rounded-md lg:rounded-xl flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none hover:scale-[1.01] hover:shadow-lg ease-out duration-300;  }
    .btn__primary-outline--sm{      @apply bg-secondary-dark border-2 border-solid border-primary font-normal text-xs md:text-base lg:text-lg text-white py-3 px-4 rounded-md lg:rounded-xl flex items-center justify-center focus:outline-none active:outline-none focus-within:outline-none hover:scale-[1.01] hover:shadow-lg ease-out duration-300;  }
    .btn__select{      @apply flex justify-between w-full items-center p-4 px-5 rounded-lg md:rounded-xl !bg-[#1F2024] text-base lg:text-lg border-[#1F2024] text-white focus:border-[#1F2024] focus:border-solid border-[3px];  }
    .btn__select-label{      @apply text-sm md:text-base 2xl:text-lg font-semibold;  }
    .btn__select-menu{      @apply block text-xs md:text-base 2xl:text-lg text-white font-semibold;  }
    .btn__select-dropdown{      @apply z-10 mt-2 w-full text-base text-white lg:text-lg outline-[#1F2024] bg-[#1F2024] rounded-xl;  }
    .section{      @apply  bg-secondary-black;  }
    .section:nth-child(even){      @apply p-8 md:p-16 lg:p-28 2xl:p-32;  }
    .section:nth-child(odd){      @apply px-8 md:p-16 lg:px-28 2xl:px-32;  }

    /* LANDING STYLES */
    .landing__title{      @apply text-2xl md:text-3xl 2xl:text-[2.75rem] text-secondary-light font-bold 2xl:leading-tight;  }
    .landing__content{      @apply text-base md:text-lg 2xl:text-2xl text-white font-normal 2xl:leading-10;  }
    .landing__sub-title{      @apply text-xl 2xl:text-3xl text-white font-bold;  }
    .landing__sub-content{      @apply text-secondary font-normal text-base 2xl:text-xl;  }
    .landing__card{      @apply flex flex-col bg-secondary-pale rounded-xl p-6 2xl:p-8 hover:scale-105 hover:shadow-lg ease-out duration-300;  }

    /* MIGRATION STYLES */
    .migrate__steps-card--title {
      @apply font-bold text-primary-light text-base md:text-lg;
    }
    .migrate__steps-card--content {
      @apply font-normal text-secondary text-sm 2xl:text-base;
    }
    .migrate-list__card--title {
      @apply text-xs lg:text-sm 2xl:text-lg font-medium text-white text-left truncate w-24;
    }
    .migrate-list__card--second-title {
      @apply text-xs lg:text-sm 2xl:text-lg font-medium text-white text-right truncate ml-auto w-24;
    }
    .migrate-list__card--sub-title {
      @apply text-sm 2xl:text-base font-normal text-primary-light;
    }
    .migrate-list__card--content {
      @apply text-sm text-white font-normal truncate;
    }

}

.loader{  border-radius:50%;  background-color:transparent;  border:4px solid #222;  border-top-color:#562caf;  -webkit-animation:spin 1s linear infinite;  animation:spin 1s linear infinite}

.animate-spin{  animation:spin 1s linear infinite}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{  -webkit-appearance:none;  margin:0}


/* Firefox */

input[type=number]{  -moz-appearance:textfield; -webkit-appearance:textfield;appearance:textfield}

/**placeholder**/
/*common web*/
::placeholder{  color:#616795}
/*Mozilla**/
::-moz-placeholder{  color:#616795}

/*spacing for template**/
@media(max-width:991px){
.card--pad{padding-left: 1rem;padding-right: 1rem;padding-bottom: 1rem !important;}
}
/* .bg-primary, .btn-primary, a, .landing__card, h2, p,
.flex.items-center .dropdown-menu img, .dropdown-menu img,
.banner__card img, .figure img,
nav .bg-secondary-light span .flex{transition: .3s ease-in-out;}
.bg-primary:hover,
.bg-primary:focus,
.btn-primary:hover,
.btn-primary:focus,
.btn-secondary:hover,
.btn-secondary:focus{
background-color: #FFF;
color: #562caf;
border-color: #FFF} */
.landing .btn-primary, .landing .btn-secondary{width: 230px;text-align: center;--tw-scale-x: none!important;--tw-scale-y: none!important;padding: 1.3rem 1rem;line-height: 1.5rem;font-size: 1.5rem}
.landing .banner__card{background-color:  #292b3a;padding: 1.5rem;transition: .3s ease-in-out}
.landing .banner__card--subtitle{font-family: 'Proxima-Regular';letter-spacing: 1px;line-height: 3rem}
.landing .banner__card--title {line-height:2rem;}
/* .banner__card--info{position: absolute;bottom: 0;width: 100%;} */
.landing .card--width{position: relative;}
.landing .banner__card:hover, .landing .banner__card:focus,
.landing .landing__card:hover, .landing .landing__card:focus{background-color: #562caf}
.flex.items-center .dropdown-menu a:hover h2, .dropdown-menu a:focus h2,
.flex.items-center .dropdown-menu span:hover h2, .dropdown-menu span:focus h2{color: #c8b0fd;}
.dropdown-menu a:hover p, .dropdown-menu a:focus p,
.dropdown-menu span:hover p, .dropdown-menu span:focus p{color: #FFF;}
.flex.items-center .dropdown-menu a:hover img, .dropdown-menu a:focus img,
.dropdown-menu .flex.items-center:hover img,
.banner__card:hover img, .banner__card:focus img{ transform: scale(1.1);}
.figure{overflow: hidden;}
.figure img, .dropdown a, .dropdown-menu img, .dropdown-menu h2, .dropdown-menu p{transition: 0.5s all ease-in-out;}
.figure:hover img, .figure:focus img{ transform: scale(1.1);}
.footer{padding-top:0!important; padding-bottom: 0!important;}
.landing__card:hover a img, .landing__card:focus a img{filter: brightness(0.25); }
.landing__card:hover h6, .landing__card:focus h6{color: #FFF; }
.landing__card:hover{--tw-scale-x: 1.02;--tw-scale-y: 1.02}
.section.py-0{padding-top: 0!important;padding-bottom:  0!important;}
nav .dropdown-menu .font-bold {font-family: 'Proxima-bold';font-weight: 500;}
nav .dropdown-menu p.font-bold {font-family: 'Proxima-Regular';}
.social-links{gap:1.3rem!important;}
.partners-logo .l1{width: 17%;}
.landing .banner__content{margin-top: 4rem;}
html{scroll-behavior: smooth}

.scroller{position: fixed;bottom: 30px;right: 30px;width: 40px;height: 40px;font-size: 20px;border: 1px solid #562caf;display: flex;align-items: center;justify-content: center;background: #fff;color: #562caf;cursor: pointer;border-radius: 100%;transition: .3s all ease-in-out;z-index: 9999}
.scroller:before{width: 0;height: 0;border-left: 5px solid transparent;border-right: 5px solid transparent;content: '';border-bottom: 5px solid #562caf;top: -2px;position: relative}
.scroller:hover, .scroller:focus{background: #562caf;color: #fff}
.scroller:hover:before, .scroller:focus:before{border-bottom: 5px solid #FFF}

nav .dropdown.active>a{background-color: #4e1fa34d; border: 1px solid #4e1fa3; color:#FFF}
nav .dropdown.active a.active img, nav .dropdown.active .dropdown-menu a.active img{transform: scale(1.1)}
nav .dropdown.active a.active h2{color: #c8b0fd;}
nav .dropdown.active a.active p{color: #FFF;}
.token-generator .outline-primary .list-none li.bg-primary:hover,
.token-generator .outline-primary .list-none li.bg-primary:focus{background: none;}
.token-create-success .bg-transparent, .token-create-success .bg-secondary-light{transition: .3s ease-in-out;}
.token-create-success .bg-transparent:hover, .token-create-success .bg-transparent:focus,
.token-create-success .bg-secondary-light:hover, .token-create-success .bg-secondary-light:focus{background-color: #FFF;color: #562caf;border-color: #FFF}
.token-create-success .bg-secondary-black:hover, .token-create-success .bg-secondary-black:focus{background-color: #562caf;}
.banner-main {position: relative;}
.landing .figure img{padding: 20px;}



/* .btn__select, .input--field{border: 3px solid rgb(51 54 77);} */
h2.title-l35{
  position: relative;
  margin-left: -35px;
}
@media (max-width: 1400px){
.landing .banner__content{margin-top: 3.5rem;padding-bottom: 2rem!important;}
.landing .btn-primary, .landing .btn-secondary{width: 190px;padding: 1rem 1rem;font-size: 1.2rem}
.subtitle br{display: none;}
}
@media (max-width: 1280px){
.landing .banner__card--subtitle {font-size: 2rem;}
.landing .banner__content{margin-top: 4rem;}
.landing .btn-primary, .landing .btn-secondary {width: 210px;font-size: 1.2rem;}
.social-links{gap:1rem!important;}

}
@media (max-width: 1199px){
.landing .btn-primary, .landing .btn-secondary {width: 190px;padding: 0.9rem;font-size: 1rem;}
.landing .banner__card--subtitle {font-size: 1.8rem;}
.banner-main .text-4xl {font-size: 2.2rem;line-height: 2.5rem;}
.landing .banner__content{margin-top:3rem;}
nav .dropdown{margin-left:0.1rem!important;}
nav .sm\:ml-6 {margin-left: 1rem;}
.footer p{font-size: 1rem!important;}
.social-links{gap:0.8rem!important;}
.partners-logo .l1{width: 16%;}
.token-generator{padding-bottom: 3rem!important;}
.token-generator .lg\:w-3\/6 {width: 60%!important;}
}
@media (max-width: 1024px){
.banner-main{height: auto;}
.banner-main .banner-screen{position: relative;min-height: inherit;}
.landing .banner__card--title {line-height: 1rem;}
.landing .banner__card {padding: 1rem;}
.landing .banner__card--subtitle {font-size: 1.7rem;line-height: 2.5rem;}
.landing--info{padding-left: 0!important;padding-right: 0!important;}
nav .dropdown{padding: 1.7rem 0!important}
nav .dropdown-menu{padding: 1rem!important;}
.social-links{gap:0.5rem!important;float: left;}
.partners-logo .l1{width: 20%;}
.f-logo, .f-copy, .f-social{text-align: center;}
.f-logo img{display: inline-block;width: 130px;}
.f-social{margin: 0 auto;}
.f-social img{width: 2.5rem;height: 2.5rem;}
.footer p {padding: 0.5rem 0;}
.footer .py-12{padding-top: 2rem;padding-bottom: 2rem}
.token-generator .lg\:w-3\/6 {width: 65%!important;}
/* .token-generator .text-danger, .step-2 .text-danger{bottom: -10px;} */
.text__grey--gradient{opacity: 0.75;}
}
@media (max-width: 991px){
.landing .btn-primary, .landing .btn-secondary {width: 170px;padding: 0.8rem;}
.landing .banner__card--subtitle {font-size: 1.6rem;}
.landing .banner-main .text-4xl{font-size:2rem;line-height:2.2rem;}
.landing .section:nth-child(even), .landing .section:nth-child(odd) {padding: 1.5rem;}
.landing .banner-screen .card--width{padding-left: 1.5rem;padding-right: 1.5rem;}
.landing .banner__content .mt-4{  margin-top: 0.5rem}
.landing .card--pad{  padding-bottom: 0rem !important;  margin: 0}
.landing .banner__content{margin-top: 2.5rem;}
.landing .landing__title {padding-top:1rem;}
nav .sm\:ml-6 {margin-left: 0rem;}
nav .md\:ml-20 {margin-left:0rem;}
nav .dropdown{margin-left: 0.2rem!important;}
nav .w-40 {width: 7rem;}
nav .dropdown-menu.w-\[42rem\]{width: 37rem!important;}
.token-generator .lg\:w-3\/6 {width: 85%!important;}
.manually{max-width: 90%;}
}
@media (max-width: 767px){
.landing .btn-primary, .landing .btn-secondary {width: 150px;padding: 0.7rem;}
.landing .banner__card--subtitle {font-size: 1.4rem;line-height: 2.2rem;}
.banner-main .text-4xl{font-size:1.9rem;line-height:2rem;}
.landing .mt-6 {margin-top: 1rem;}
.landing .landing--info .mt-6 {margin-top:0rem;}
nav .w-40 {width: 4rem;}
nav .dropdown{margin-left:0rem!important;}
nav .dropdown > a{padding: 0.3rem 0.8rem!important;font-size: 0.9rem;}
.partners-logo .l1{width: 28%;}
.landing .landing__sub-title{font-size: 1.1rem;line-height: 1.6rem}
.landing .landing__sub-content{font-size: 1rem;line-height: 1.4rem}
.services .mt-8{margin-top: 1rem;}
nav .dropdown-menu.w-\[42rem\]{width: 32rem!important}
nav .dropdown-menu .gap-3 {gap: 0.4rem;}
.token-generator .lg\:w-3\/6 {width: 90%!important;}
.token-generator .btn__select{padding: 0.8rem 1rem;font-size: 1rem;}
.token-generator .btn__select-menu{font-size: 0.8rem;line-height: 1.5rem;}
.token-generator .input--field, .step-2 .input--field {padding: 0.8rem 1rem;font-size: 0.875rem;}
.token-generator .text-danger, .step-2 .text-danger{font-size: 0.8rem;}
/* .token-generator .text-danger, .step-2 .text-danger{bottom: -7px;} */
.token-generator  .p-4.bg-primary{padding: 0.7rem;margin-top: 0;}
.create-launchpad .text-secondary, .create-launchpad .text-primary-light{font-size: 0.9rem;}
/* .btn-primary, .btn-primary-outline, .bg-primary, button.bg-transparent, button.bg-secondary-black{padding:0.7rem;font-size:1rem;line-height: 1.3rem} */
#mobile-menu a{
  color: #FFF;
  border-bottom: 1px solid #cccccc26;
  border-radius: 0;
  cursor: pointer;
}
#mobile-menu a:hover p, #mobile-menu a:focus p {
  color: #c8b0fd;
}
/* .bg-secondary-dark {
  background-color: rgb(255 255 255 / 0.06);
} */
/* .bg-secondary-dark .bg-secondary-dark, .bg-secondary-dark .bg-secondary-light{
  background: none;
} */
/* .migrate-tokens .bg-secondary-dark {
  background-color: rgb(25 26 30 / 1)!important;
} */
#mobile-menu .bg-secondary-dark .bg-secondary-dark a{
  margin-bottom: 1px;
  display: block;
}
/* #mobile-menu .bg-secondary-dark div:last-child a{
  border-bottom: 0;
} */
nav .bg-secondary-light span:hover .flex,
nav .bg-secondary-light span:focus .flex,
nav .bg-secondary-light span .flex.active,
#mobile-menu .bg-secondary-dark .bg-secondary-dark a:hover,
#mobile-menu .bg-secondary-dark .bg-secondary-dark a:focus,
#mobile-menu .bg-secondary-dark .bg-secondary-dark a.active
{
  background-color: rgb(78 31 163 / 0.3);
}
#mobile-menu>.bg-secondary-dark{
  overflow-y: auto;
  height: calc(100vh - 80px);
}
nav.fixed{border-bottom: 0;}
nav.fixed>.mx-auto>.h-20{border-bottom: 1px solid #cccccc26;}
h2.title-l35{
  margin-left:0;
}
}
@media (min-width: 640px){
  .landing .sm\:hidden{display: none}
}
@media (max-width: 639px){
.landing .btn-primary, .landing .btn-secondary {width: 140px;padding: 0.6rem;font-size:0.9rem;}
.landing .banner__card--subtitle {font-size: 1.4rem;}
.landing .banner-main .text-4xl{font-size:1.8rem;line-height:1.9rem;padding-bottom:0rem;}
.m-logo{margin-left:3rem;}
nav button, nav .ml-0{margin-left:0.5rem!important;}
nav .mobile-toggle{margin-left:0rem!important;}
.partners-logo .l1{width: 30%;}
.footer p {padding: 0.2rem 0;}
.footer .py-12{padding-top: 1.5rem;padding-bottom: 1.5rem}
.f-social img{width: 2rem;height: 2rem;}
.landing .landing__sub-title{font-size: 1rem;line-height: 1.5rem}
.landing .landing__sub-content{font-size: 0.9rem;line-height: 1.2rem}
.landing .landing__card{padding: 1rem;}
.landing .landing__card .pt-4{padding-top: 0}
nav .text-base{font-size: 0.9rem;cursor: pointer}
nav .text-base.bg-secondary-light:hover, nav .text-base.bg-secondary-light:focus, nav .active .text-base.bg-secondary-light{background-color: rgb(51 54 77);color: #FFF}

/* nav .bg-secondary-light span .flex{border-bottom: 1px solid #282437;} */
nav .text-base.bg-secondary-light{margin-bottom: 1px;}
.scroller{bottom: 10px;right: 10px;width: 30px;height: 30px}
nav .bg-secondary-light span .flex {padding-top: 0.7rem;padding-bottom: 0.7rem;}
.create-launchpad .text-secondary, .create-launchpad .text-primary-light{font-size: 0.8rem;}
#launchPad .create-launchpad .mt-7{margin-top: 1rem}
#launchPad .text-xl {font-size: 1.1rem;}
.token-generator .input--label.mb-2{font-size: 0.8rem;}
#launchPad .card .input--field, #launchPad .p-4{padding: 0.8rem;font-size: 0.8rem}
}
@media (max-height: 600px){
.banner-main .banner-screen{position: relative;min-height: inherit;}
.banner-main{height: auto;}
}
@media (max-width: 480px){
.landing .btn-primary, .landing .btn-secondary {width: 120px;padding: 0.5rem;}
.landing .banner__card--subtitle {font-size: 1.2rem;line-height: 2rem;}
.landing .banner-main .text-4xl{font-size:1.6rem;line-height:1.8rem;}
.landing .section:nth-child(even), .landing .section:nth-child(odd) {padding:1rem;}
.landing .banner-screen .card--width{padding-left: 1rem;padding-right: 1rem;}
.landing .landing__content {font-size:0.9rem;line-height: 1.3rem;}
.py-5 {padding-top: 1rem;padding-bottom: 1rem;}
.landing__title {font-size: 1.2rem;line-height:1.6rem;}
.py-4 {padding-top:0.5rem;padding-bottom:0.5rem;}
.landing .banner__content .text-base{  font-size: 0.9rem;  line-height: 1.4rem}
.landing .banner__content .mt-4{  margin-top: 0.2rem}
.landing .banner__content{margin-top: 2.5rem;padding-bottom: 2rem;}
.partners-logo .l1{width: 47%;}
.footer p{font-size:0.8rem!important;}
.f-social img{width:1.8rem;height:1.8rem;}
.services .mt-8{margin-top: 0.5rem;}
.partners-logo .l1:last-child{margin-bottom:2rem;}
/* nav .py-2{padding-top: 0rem;padding-bottom: 0rem} */
nav .py-2.bg-secondary-light{padding-top: 0.2rem;padding-bottom: 0.2rem}
.token-generator .lg\:w-3\/6 {width: 95%!important;padding: 1rem;}
.token-generator{padding-bottom: 2rem!important;padding-left: 1rem!important;padding-right: 1rem!important;padding-top: 5rem!important}
.token-generator .btn__select{padding: 0.5rem 1rem;font-size: 0.9rem;}
.token-generator .btn__select-menu{font-size: 0.8rem;line-height: 1.5rem;}
.token-generator .input--field, .step-2 .input--field{padding: 0.5rem 1rem;font-size: 0.8rem;}
.token-generator .text-danger, .step-2 .text-danger{font-size: 0.7rem;}
.token-generator .p-4.bg-primary{padding: 0.5rem;}
.token-generator .input--label.mb-2, .step-2 .input--label.mb-2 {margin-bottom: 0.1rem;}
/* .token-generator .font-semibold {font-family: 'Proxima-Regular';    font-weight: 600;} */
nav .bg-secondary-light span .flex {padding-top: 0.5rem;padding-bottom: 0.5rem;}
.token-create-success{padding:1rem ;}
.token-create-success button.text-base.py-3{font-size: 0.9rem;padding-top: 0.5rem;padding-bottom: 0.5rem}
.token-create-success p.text-md{font-size: 0.9rem;}
#launchPad #launchPadTabs .px-6{padding-left: 1rem;padding-right: 1rem}
#launchPad .card .input--field, #launchPad .p-4 {padding: 0.6rem;font-size: 0.7rem}
/* .btn-primary, .btn-primary-outline, .bg-primary, button.bg-transparent, button.bg-secondary-black{  padding:0.6rem;  font-size:0.9rem;  line-height: 1.2rem} */
.custom-dropzone{min-height: 9rem!important;height: 9rem!important;padding: 10px;}
textarea#message.h-48 {height:9rem;}
#tokenMigration h2.text-xl{font-size: 1.1rem;}
#tokenMigration p.text-base{font-size: 0.9rem;}
.space-x-6 > :not([hidden]) ~ :not([hidden]).ml-2{margin-left: 0.5rem!important;}
.span-dots{font-size: 15px!important;}
#tokenMigration button.bg-primary, #tokenMigration button.bg-primary-outline{
  padding: 0.6rem 1.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
}
#tokenMigration .input--field{padding: 0.6rem;}
#tokenMigration .h-44 {
  height: 9rem;
}
#tokenMigration .card p{overflow: hidden;}
.title-crypto br{display: none;}
}
@media (max-width: 400px){
.landing .btn-primary, .landing .btn-secondary {width: 110px;padding: 0.3rem;font-size: 0.8rem;}
.landing .banner__card--subtitle {font-size: 1.1rem;}
.landing .banner-main .text-4xl{font-size:1.3rem;line-height:1.7rem;}
.landing .landing__title{  font-size: 1.1rem;line-height: 1.5rem}
.landing .banner__content .btn-primary{font-size: 0.8em;line-height: 1.3rem}
.token-generator .lg\:w-3\/6 {width: 100%!important;}
.m-logo{margin-left: 2.53rem}
.create-launchpad .text-secondary, .create-launchpad .text-primary-light{font-size: 0.8rem;}
/* .btn-primary, .btn-primary-outline, .bg-primary, button.bg-transparent, button.bg-secondary-black{padding:0.5rem;font-size:0.85rem;line-height: 1.1rem} */
#tokenMigration h2.text-xl{font-size: 1rem;}
#tokenMigration p.text-base{font-size: 0.85rem;}
#tokenMigration button.cursor-not-allowed .animate-spin{
  margin:0px!important;
}
/* #tokenMigration button.cursor-not-allowed{padding: 0.6rem 0.6rem;} */
}


/* BUTTON ANIMATION STYLEs */
  .btn__outline--anim {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  }

  .btn__primary--anim {
    @apply shadow-primary-default hover:shadow-primary-hover hover:bg-secondary-dark transition-all duration-150 ease-in-out;
  }
  .btn__primary-light--anim {
    @apply shadow-primary-light-default hover:shadow-primary-light-hover hover:bg-secondary-dark transition-all duration-150 ease-in-out;
  }


.cdk-global-scrollblock nav.fixed {
  @apply bg-secondary-black;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #33364d inset !important;
    -webkit-text-fill-color: #fff !important;
    background-image: none !important;
    border: 3px solid #33364d;
    background-clip: content-box !important;
    caret-color: #fff;
}
nav .dropdown>a{
  border: 1px solid transparent;
}
nav .dropdown:hover>a ,nav .dropdown:focus>a{
  background-color: #4e1fa34d;
  border: 1px solid #4e1fa3;
  color:#FFF;
}

/* .shadow-btn{box-shadow: 0px 0px 10px 0px rgb(78 31 163 / 63%);} */
.bg__primary {
  border-color: #4E1FA3;
  color: #fff;
  box-shadow: 0 0 40px 40px #4e1fa34d inset, 0 0 0 0 #4e1fa34d;
  transition: all 150ms ease-in-out;
}
.bg__primary:hover {
  box-shadow: 0 0 10px 0 #4e1fa34d inset, 0 0 10px 4px #4e1fa34d;
}
.bg__primary--sm:hover {
  border-color: #4E1FA3;
  color: #fff;
  box-shadow: 0 0 10px 0 #4e1fa34d inset, 0 0 10px 4px #4e1fa34d;
  transition: all 150ms ease-in-out;
}
.bg__primary--sm {
  background-color: #4e1fa34d;
  border-color: #4e1fa34d;
  box-shadow: 0 0 40px 40px #4e1fa34d inset, 0 0 0 0 #4e1fa34d;
}

::-webkit-scrollbar {
  height: 4px;    
  width: 10px;
  background: #21222C;
}

/* Track */
::-webkit-scrollbar-track {
  background: #21222C;
  box-shadow: inset 0 0 5px #21222C;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e1fa34d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e1fa34d;
  border-radius: 10px;
}
.star1{left: -4rem;top:-5rem;}

.banner{position: relative;}
.banner:before{
  @apply bg-no-repeat bg-[url(/../assets/images/bg-banner.png)];
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 400px;
  content: '';
  background-size: cover;
  background-position: top center;
}
.sub-banner{background-position: center 32%!important;}

@media(max-width:1920px){
.banner:before{
    height: 350px;
}
}
@media(max-width:1800px){
.banner:before{
    height: 320px;
}
}
@media(max-width:1700px){
.banner:before{
    height: 300px;
}
}
@media(max-width:1600px){
.banner:before{
    height:280px;
}
}
@media(max-width:1500px){
.banner:before{
    height: 260px;
}
}
@media(max-width:1400px){
.banner:before{
    height:240px;
}
}
@media(max-width:1280px){
.banner:before{
    height: 220px;
}
/* .nav__menu--item {
  font-size: 0.75rem;
} */
}
@media(max-width:1024px){
.banner:before{
    height: 190px;
}
.sub-banner{background-position: center 13%!important;}
}
@media(max-width:991px){
.banner:before{
    height: 170px;
}
.sub-banner{background-position: center 10%!important;}
.text__grey--gradient {
  line-height: 4rem!important;
}
.single-title{
  line-height:2.5rem!important;
}
.para-description br{display: none;}
#cdk-overlay-0 {
  left: 8px !important;
}
.owl-dt-timer-content .owl-dt-timer-input {
  font-size: .75rem !important;
}
.owl-dt-control-arrow-button svg {
  width: 40% !important;
  height: 40% !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  width: 70% !important;
    height: 70% !important;
}
.owl-dt-timer-divider:before, .owl-dt-timer-divider:after {
  width: 0.25rem !important;
    height: 0.25rem !important;
}
.owl-dt-timer-divider:before {
  top: 40% !important;
}
.owl-dt-timer-box {
  width: 20% !important;
}
.owl-dt-timer {
  height: 5rem !important;
  padding: 0 !important;
}
.owl-dt-calendar-main {
  padding: 0 !important;
}
}
@media(max-width:767px){
.banner:before{
    height:140px;
}
.peer:focus ~ .peer-focus\:-translate-y-6 {
  --tw-translate-y: -1rem;
}
.peer.top-3 {
  top: 20px;
}
}
@media(max-width:640px){
.banner:before{
    height: 120px;
}
.sub-banner{background-position: center 5%!important;}
.roadmap-carousel .owl-item {
  display: flex;
  justify-content: center;
}
.input--label {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
/* .close-modal{
  position: absolute;

} */
}
@media(max-width:576px){
.banner:before{
    height:100px;
}
.sub-banner{background-position: center 0%!important;}

}
@media(max-width:480px){
.banner:before{
    height:80px;
}
.blog-subtitle{font-size: 0.9rem;}
.sub-banner{background-position: center 0%!important;}
}
@media(max-width:400px){
.banner:before{
    height:70px;
}
.migrator-listings .col-span-6{
  grid-column: span 12 / span 12;
}
.sub-banner{background-position: center 0%!important;}
}
@media(max-width:360px){
.banner:before{
    height:60px;
}
.migrator-listings .col-span-6{
  grid-column: span 12 / span 12;
}
#cdk-overlay-0 {
  left: 8px !important;
}
.owl-dt-timer-content .owl-dt-timer-input {
  font-size: .75rem !important;
}
.owl-dt-control-arrow-button svg {
  width: 40% !important;
  height: 40% !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  width: 70% !important;
    height: 70% !important;
}
.owl-dt-timer-divider:before, .owl-dt-timer-divider:after {
  width: 0.25rem !important;
    height: 0.25rem !important;
}
.owl-dt-timer-divider:before {
  top: 40% !important;
}
.owl-dt-timer-box {
  width: 20% !important;
}
.owl-dt-timer {
  height: 5rem !important;
  padding: 0 !important;
}

}

@media(max-height:500px){
.banner{
  height: 100%;
}
.banner .min-h-screen {
  min-height: auto;
}
}

input[type=radio]:checked{
  background-color:#9577D4;
}
input[type=radio]:checked ~ label {
  color: blue;
  border-color: rgb(161 131 224);
}
.tooltip.tooltip-truncate.tooltip-shadow.tooltip-top.tooltip-show{
  max-width: 250px!important;
  
}
.tooltip-truncate, ::ng-deep .tooltip {
  width: fit-content;
  max-width: 100% !important;
  cursor: pointer;
}
